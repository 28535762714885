var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"activeModal":_vm.activeModal,"widthModal":1090},on:{"closeModal":_vm.closeModal}},[_c('v-card',[_c('v-toolbar',{staticClass:"modal-cadastro-toolbar",attrs:{"flat":"","height":"40px"}},[_vm._v(" Relatório de Prêmios por Revendedor "),_c('v-spacer'),_c('v-icon',{staticClass:"modal-cadastro-close",on:{"click":_vm.closeModal}},[_vm._v("close")])],1),_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-3 pt-0 pb-1"},[_c('strong',[_vm._v("Data de Referência:")]),_vm._v(" "+_vm._s(_vm.data)+" ")]),_c('v-col',{staticClass:"text-right pa-3 pt-0 pb-1"},[_c('strong',[_vm._v("Revendedor:")]),_vm._v(" "+_vm._s(_vm.revendedor)+" ")])],1),_c('v-row',{staticClass:"align-end"},[_c('v-col',{staticClass:"pa-3 pt-0 pb-1"},[_c('date')],1)],1)],1)],1)],1)],1),(_vm.loadingModal)?_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"300px","width":"100%"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-container',{staticClass:"mt-0",attrs:{"fluid":""}},[_c('v-col',[_c('div',{staticClass:"box-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.arrGamesGuessWinners,"items-per-page":5,"mobile-breakpoint":300,"width":"300px","height":"auto","flat":"","dense":""},scopedSlots:_vm._u([{key:"item.entityId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.namePremio(item))+" ")]}},{key:"item.gameNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gameNumber)+" ")]}},{key:"item.lotteryGuesses.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.lotteryGuesses.value))+" ")]}},{key:"item.prizeValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.entities !== _vm.revendedor && item.entities.entityTypeId !== 6 && item.modalityId !== 'BINGO' && item.origin ? 'color-red' : '']},[_vm._v(" "+_vm._s(_vm._f("currency")(item.prizeValue))+" ")])]}},{key:"item.lotteryGuesses.lotteryHeaders.sweepstakes.dtDraw",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.lotteryGuesses.lotteryHeaders.sweepstakes.dtDraw,'DD/MM/YYYY HH:mm:ss'))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',{},[_c('th',{attrs:{"colspan":"8"}},[_vm._v("Total: ")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalJogos))+" ")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalPremios)))])])])],2)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
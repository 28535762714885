<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal" :widthModal="1090">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Relatório de Prêmios por Revendedor  
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data de Referência:</strong> {{ data }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row class="align-end">
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      
      <v-container fluid class="mt-0" v-else>
        <v-col>
          <div class="box-table">
            <v-data-table
              :headers="headers"
              :items="arrGamesGuessWinners"
              :items-per-page="5"
              :mobile-breakpoint="300"
              width="300px"
              height="auto"
              flat
              dense
            >
              <!-- <template v-slot:body="{ items }">
                
              </template> -->

              <template v-slot:item.entityId="{ item }">
                  {{ namePremio(item) }}
              </template>

              <template v-slot:item.gameNumber="{ item }">
                {{ item.gameNumber }}
              </template>

              <template v-slot:item.lotteryGuesses.value="{ item }">
                {{ item.lotteryGuesses.value | currency }}
              </template>

              <template v-slot:item.prizeValue="{ item }">
                <span :class="[item.entities !== revendedor && item.entities.entityTypeId !== 6 && item.modalityId !== 'BINGO' && item.origin ? 'color-red' : '']"> {{ item.prizeValue | currency }} </span>
              </template>

              <template v-slot:item.lotteryGuesses.lotteryHeaders.sweepstakes.dtDraw="{ item }">
                {{ item.lotteryGuesses.lotteryHeaders.sweepstakes.dtDraw | datas('DD/MM/YYYY HH:mm:ss') }}
              </template>

              <template slot="body.append">
                <tr class="">
                  <th colspan="8">Total: </th>
                  <th>{{ totalJogos | currency }} </th>
                  <th>{{ totalPremios | currency }}</th>

                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'

import validacao from './validacao'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalJogosWinners',
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/views/components/modal'),
    date: () => import('@/views/components/date')
  },
  data: () => ({
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    loadingModal: false,
    nrJogo: null,
    revendedor: '',
    data: '',
    arrGamesGuessWinners: [],
    headers: [
      { sortable: false, class: 'table-header text-center', text: 'Rev. ou WEB / Pago', value: 'entityId' },
      { sortable: false, class: 'table-header', text: 'Bilhete', value: 'gameNumber' },
      { sortable: false, class: 'table-header', text: 'Código Bilhete', value: 'lotteryGameCod' },
      { sortable: false, class: 'table-header', text: 'Modalidade', value: 'lotteryGuesses.lotteryHeaders.modalities.name' },
      { sortable: false, class: 'table-header', text: 'Prêmios', value: 'prizeRange' },
      { sortable: false, class: 'table-header', text: 'Loteria', value: 'lotteryGuesses.lotteryHeaders.sweepstakes.cod' },
      { sortable: false, class: 'table-header', text: 'Data do sorteio', value: 'lotteryGuesses.lotteryHeaders.sweepstakes.dtDraw' },
      { width: '210', sortable: false, class: 'table-header', text: 'Palpites', value: 'lotteryGuesses.guess' },
      { sortable: false, class: 'table-header', text: 'Valor jogo', value: 'lotteryGuesses.value' },
      { sortable: false, class: 'table-header', text: 'Valor Prêmios', value: 'prizeValue' },
    ]
  }),
  
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes', 'listGamesWinners']),
    ...validacao,
    listAll () {
      return this.arrGamesGuessWinners
    },
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },


    totalPremios () {
      return this.arrGamesGuessWinners.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.prizeValue)
      }, 0)      
    },

    totalJogos () {
      return this.arrGamesGuessWinners.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.lotteryGuesses.value)
      }, 0)      
    }
  },
  mounted () {
    const self = this
    Events.$on('modal::historicoVendas::jogosWinners', (e) => {
      self.activeModal = true
      self.data = self.displayDate(e.data, e.hasMonthOrDate)
      self.revendedor = e.entity.entity_cod

      self.loadingModal = true

      self.formatDate(e)

      self.getListJogosWinners({ dtInicial: self.dtInicial, dtFinal: self.dtFinal, entityId: e.entity.entity_id, pending: e.pending })
        .then(() => self.gamesGuessWinners())
        .finally(() => self.loadingModal = false)
    })

    Events.$on('modal::historicoVendas::jogosWinnersPending', (e) => {
      self.activeModal = true
      self.data = self.displayDate(e.data, e.hasMonthOrDate)
      self.revendedor = e.entity.entity_cod

      self.loadingModal = true

      self.formatDate(e)

      self.getListJogosWinners({ dtInicial: self.dtInicial, dtFinal: self.dtFinal, entityId: e.entity.entity_id, pending: e.pending })
        .then(() => self.gamesGuessWinners())
        .finally(() => self.loadingModal = false)
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas', 'getListJogosWinners', 'limparItem']),
    namePremio (item) {
      if (item.entities.entityTypeId === 6) {
        return `${item.entities.jsonData.fullName} / ${this.revendedor}`
      }
      console.log(item)
      if (item.entities.entityTypeId === 4 && item.origin) {
        return `${item.origin.cod } / ${item.entities.cod}`
      }

      if (item.entities.entityTypeId === 4 && !item.params) {
        return `${item.entities.cod}`
      }
      return this.revendedor
    },
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.arrGamesGuessWinners = []
      this.limparItem()
    },

    formatDate (data) {
      if (data.hasMonthOrDate === 2) {
        this.dtInicial = moment(data.data, 'MM/YYYY').startOf('month').format('YYYY-MM-DD')
        this.dtFinal = moment(data.data, 'MM/YYYY').endOf('month').format('YYYY-MM-DD')

        return
      } 

      this.dtInicial = moment(data.data).format('YYYY-MM-DD')
      this.dtFinal = moment(data.data).format('YYYY-MM-DD')
     },

     
    gamesGuessWinners () {
      this.listGamesWinners.forEach(item => {
        item.winners.lotteryGames.lotteryGuessWinners.forEach(subItem => {
          subItem.gameNumber = item.winners.lotteryGames.gameNumber
        })

        item.winners.lotteryGames.lotteryGuessWinners.forEach(e => {
          e.entities = item.entities
          if (item.params !== null && (item.params.originalPayerEntityId || item.params.payerEntityId)) {
            e.origin = item.params.originalPayerEntityId || item.params.payerEntityId || null
          }
        })
        this.arrGamesGuessWinners.push(...item.winners.lotteryGames.lotteryGuessWinners)
      })
    },

    displayDate (date, type) {
      if (type === 2) return moment(date).format('MM/YYYY')

      return moment(date).format('DD/MM/YYYY')
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::jogosWinners')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
  .box-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td span.color-red{
    color: lightcoral;
  }
</style>